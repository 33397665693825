import React, { useState, useEffect } from 'react';
import Articles from './Articles.js';
import './App.css';

function App() {

  const [theme, setTheme] = useState(() => {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    } else {
      return 'light';
    }
  });

  useEffect(() => {
    const matcher = window.matchMedia('(prefers-color-scheme: dark)');
    const changeTheme = () => {
      if (matcher.matches) {
        setTheme('dark');
      } else {
        setTheme('light');
      }
    };
    matcher.addListener(changeTheme);
    return () => matcher.removeListener(changeTheme);
  }, []);

  useEffect(() => {
    document.body.className = theme === 'dark' ? "App dark" : "App";
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  }

  return (
    <div className={`App ${theme}`}>
      <div className="header-container">
        <header className="App-header">
          <h1>Hacker News</h1>
          <label className="switch">
            <input type="checkbox" onChange={toggleTheme} checked={theme === 'dark'} />
            <span className="slider round"></span>
          </label>
        </header>
      </div>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Articles />
      </React.Suspense>
    </div>
  );
}

export default App;
