import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ArticleCard from './ArticleCard.js';
import { BarLoader } from 'react-spinners';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

function Articles() {
  const [articles, setArticles] = useState([]);
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState("score");
  const [isUpdating, setIsUpdating] = useState(false);
  const [visitedLinks, setVisitedLinks] = useState([]);
  const [articlesCount, setArticlesCount] = useState(0); // Initialize articlesCount to 0
  const [loadingTime, setLoadingTime] = useState(null);

  const handleLinkClick = (id) => {
    const newVisitedLinks = [...visitedLinks, id];
    setVisitedLinks(newVisitedLinks);
    localStorage.setItem('visitedLinks', JSON.stringify(newVisitedLinks));
  };

  useEffect(() => {
    const fetchArticles = async () => {
      setIsUpdating(true);
      const start = performance.now();
      const res = await axios.get('https://hacker-news.firebaseio.com/v0/topstories.json');
      const articlesToFetch = res.data.slice(0, articlesCount + 20);
      const newArticlesArray = await Promise.all(
        articlesToFetch.map((id, index) =>
          axios.get(`https://hacker-news.firebaseio.com/v0/item/${id}.json`)
            .then(article => article.data)
        )
      );
      const end = performance.now();
      setLoadingTime(`Loading time: ${(end - start).toFixed(2)} ms`);
      setArticles(prevArticles => [...prevArticles, ...newArticlesArray].sort((a, b) => a.number - b.number));
      setIsUpdating(false);

      const savedVisitedLinks = JSON.parse(localStorage.getItem('visitedLinks'));
      if (savedVisitedLinks) {
        setVisitedLinks(savedVisitedLinks);
      }
    };

    fetchArticles();

    const interval = setInterval(fetchArticles, 1800000);

    return () => {
      clearInterval(interval);
    };
  }, [articlesCount]); // Add articlesCount to the dependency array

  const filteredArticles = articles.filter(article => article.title.toLowerCase().includes(filter.toLowerCase()));

  const sortArticles = (articles) => {
    if (sort === "newest") {
      return articles.sort((a, b) => b.time - a.time);
    } else if (sort === "oldest") {
      return articles.sort((a, b) => a.time - b.time);
    } else if (sort === "score") {
      return articles.sort((a, b) => b.score - a.score);
    } else if (sort === "comments") {
      return articles.sort((a, b) => b.descendants - a.descendants);
    } else {
      return articles.sort((a, b) => a.number - b.number);
    }
  };

  const sortedArticles = sortArticles(filteredArticles);

  const handleSortChange = (selectedSort) => {
    setSort(selectedSort);
  };

  const sortOptions = [
    { value: 'score', label: 'Top' },
    { value: 'original', label: 'Trending' },
    { value: 'newest', label: 'Newest' },
    { value: 'comments', label: 'Comments' }
  ];

  const handleLoadMore = () => {
    setArticlesCount(prevCount => prevCount + 20);
  };

  return (
    <div className='parent-container'>
      <div className="header-container">
        <div className="tab-container">
          {sortOptions.map(option => (
            <button
              key={option.value}
              className={`tab ${sort === option.value ? 'active' : ''}`}
              onClick={() => handleSortChange(option.value)}
            >
              {option.label}
            </button>
          ))}
        </div>
        <div className="filter-container">
          <input className='filter'
            type="text"
            placeholder="Filter by title..."
            value={filter}
            onChange={e => setFilter(e.target.value)}
          />
        </div>
      </div>
      <div className="card-container">
        <TransitionGroup className="card-container">
          {sortedArticles.map((article) => (
            <CSSTransition
              key={article.id}
              timeout={500}
              classNames="fade"
            >
              <div className='article-row'>
                <div className='flex-card'>
                  <ArticleCard article={article} visitedLinks={visitedLinks} handleLinkClick={handleLinkClick} />
                </div>
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
        {isUpdating && (
          <div className="loader-container">
            <BarLoader color="orange" loading={isUpdating} size={15} />
            <p>Updating articles...</p>
          </div>
        )}
        {!isUpdating && (
         <button className="load-more" onClick={handleLoadMore}>Load 20 more</button>
       )}
      </div>
      {loadingTime && (
        <div className="loading-time">
          {loadingTime}
        </div>
      )}
    </div>
  );
}

export default Articles;
