import React from 'react';
import { formatDistanceToNow } from 'date-fns';

export default function ArticleCard({ article, visitedLinks, handleLinkClick }) {
  const { title, url, score, time, descendants, id } = article;
  const isVisited = visitedLinks.includes(id);
  let timeAgo = "unknown time";
  if (time) {
    const date = new Date(time * 1000);
    timeAgo = formatDistanceToNow(date) + " ago";
  }

  return (
    <div className={`card ${isVisited ? 'visited' : ''}`}>
      <a href={url} target="_blank" rel="noopener noreferrer" onClick={() => handleLinkClick(id)}>
        <h2>{title}</h2>
      </a>
      <p>
        <span>{score}</span> points &#x2022; {timeAgo} &#x2022;{" "}
        <a href={`https://news.ycombinator.com/item?id=${id}`} target="_blank" rel="noreferrer">
          {descendants} comments
        </a>
      </p>
    </div>
  );
}
